<script lang="ts" setup>
import { useI18n } from '@/plugins/i18n'

const { t } = useI18n()
</script>

<template>
  <div class="h-full flex flex-col items-center justify-center text-gray-600 text-center">
    <h1>
      {{ t('calendar.common.errors.unexpected_error') }}
    </h1>
    <p>
      {{ t('calendar.common.errors.try_again') }}
    </p>
  </div>
</template>
