/**
 * The application is rendered by a WebView from the native iOS application on iPads
 * It is also used as a standalone web application.
 * This variable tells whether or not it is rendered from a web view
 */
export const isNativeApp = {
  get value() {
    return !!window.sendRequestToPos
  }
}
