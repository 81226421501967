import type { Staff } from '@/types'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export type StaffStoreState = ReturnType<typeof useStaffStore>['$state']

export const useStaffStore = defineStore('staff', () => {
  const staff = ref<Staff[]>([])

  function set(value: Staff[]) {
    staff.value = value.slice().sort((a, b) => {
      return a.name.localeCompare(b.name)
    })
  }

  return {
    staff,
    set
  }
})
