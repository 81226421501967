import type { Appointment } from '@/types'
import { v4 as uuid } from 'uuid'
import { PosAPIError } from './PosApiError'
import { useSentry } from '../sentry'
import type { Cart } from './types/Cart'
import { useI18n } from '../i18n'
import { Env } from '@/env'

const DEBUG_MODE = false
function debug(...args: any[]) {
  const msg = args
    .map((arg) => (typeof arg === 'object' ? JSON.stringify(arg, null, 2) : arg))
    .join(' ')

  if (!DEBUG_MODE) {
    return console.log(msg)
  }

  window.alert(msg)
}

/**
 * Simple wrapper over the sdk.
 * If the application is rendered using a webview from the Tillhub native app,
 * the window object will have sendRequestToPos and handleRequestFromPos methods
 * @docs https://github.com/tillhub/TillhubWebViewAPI-iOS
 */
class PosAPI {
  private sentry = useSentry()

  async getActiveBranch() {
    return await this.sendRequest<{ branch: { id: string } }>('get_branch')
  }

  async getCart() {
    return await this.sendRequest<{ cart: Cart }>('get_cart')
  }

  async getSession() {
    return await this.sendRequest<{ session: { token: string } }>('get_session', {
      key: Env.VITE_POS_SESSION_KEY
    })
  }

  async updateCart(appointment: Appointment) {
    const cartResult = await this.getCart()

    const cartLineItems = cartResult?.message_payload?.cart?.items ?? []

    const appointmentLineItems = appointment.lineItems.map((lineItem) => {
      return {
        id: null,
        name: null,
        product_id: lineItem.service!.linked_product_id,
        // POS will fill those
        price_per_unit: null,
        vat_rate: null,
        discount: null,
        quantity: 1,
        price_change_allowed: true,
        quantity_change_allowed: true,
        immutable: true,
        meta_data: {
          external_reference_custom_id: lineItem.service!.linked_product_id
        }
      }
    })

    const updatedCartLineItems = cartLineItems.concat(appointmentLineItems)

    return await this.sendRequest('update_cart', {
      cart: {
        // POS will calculate this
        value: null,
        external_reference_custom_id: appointment.id,
        items: updatedCartLineItems
      }
    })
  }

  private async sendRequest<TPartialResponse = Record<string, any>>(
    messageName: string,
    payload: Record<string, any> = {}
  ) {
    return new Promise<PosAPIResult<TPartialResponse> | undefined>((resolve, reject) => {
      const data = JSON.stringify(
        {
          message_header: {
            message_name: messageName,
            message_type: 'request',
            message_id: uuid(),
            message_version: '1.0'
          },
          message_payload: payload
        },
        null,
        4
      )

      debug(`[PosAPI] Dispatching event "${messageName}" with payload `, payload)

      if (!window.sendRequestToPos) {
        return resolve(undefined)
      }

      function onSuccess(result: string) {
        const parsedResult = JSON.parse(result) as PosAPIResult

        if (parsedResult.message_payload.status === 'failure') {
          return onError(result)
        }

        debug(`[PosAPI] Received successful response for "${messageName}" `, result)

        resolve(JSON.parse(result))
      }

      const { sentry, createErrorFromPayload } = this

      function onError(errorPayload: string) {
        // Added for debugging during dev phase, need to see if we will need it later as well
        // as certain errors can indicate that we dont sent data in the right format
        sentry.captureException(new Error('Pos API Exception'), {
          extra: {
            payload: errorPayload
          }
        })

        debug(`[PosAPI] Received error response for "${messageName}" `, errorPayload)

        reject(createErrorFromPayload(errorPayload))
      }

      window.sendRequestToPos(data, onSuccess, onError)
    })
  }

  private createErrorFromPayload(errorPayload: string) {
    let parsed: PosAPIResult

    try {
      parsed = JSON.parse(errorPayload)
    } catch (error) {
      return new PosAPIError(errorPayload)
    }

    return new PosAPIError(
      parsed.message_payload?.error?.message!,
      parsed.message_payload?.error?.code
    )
  }
}

export function usePosApi() {
  return new PosAPI()
}

interface PosAPIResult<T = Record<string, any>> {
  message_header: {
    message_name: string
    type: 'response' | 'request'
    message_id: string
    message_version: string
  }
  message_payload: Partial<T> & {
    status: 'success' | 'failure'
    error?: {
      message: string
      code?: string
    }
  }
}
