import * as pinia from 'pinia'

export function createPinia() {
  const plugin = pinia.createPinia()

  plugin.use((context) => {
    return {
      $axios: context.app.config.globalProperties.$axios
    }
  })

  return plugin
}
