import { useClientAccountId } from '@/composables/useClientAccountId'
import { useThFetch } from '@/composables/useThFetch'
import type { Configuration } from '@/types/Configuration'
import type { UseFetchOptions } from '@vueuse/core'

export function useConfigurationQuery(options?: UseFetchOptions) {
  const accountId = useClientAccountId()
  return useThFetch(() => `/v0/configurations/${accountId.value}?owner=self`, {
    afterFetch(ctx) {
      return {
        ...ctx,
        data: ctx.data.results[0]
      }
    },
    ...options
  }).json<Configuration>()
}
