import type { User } from '@/types/User'
import { useThFetch } from '@/composables/useThFetch'
import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useDayjs } from '@/plugins/dayjs'

export type AuthStoreState = ReturnType<typeof useAuthStore>['$state']

export const useAuthStore = defineStore('auth', () => {
  const dayjs = useDayjs()

  const isAuthenticated = computed(() => {
    return isSessionTokenExpired.value === false && !!sessionToken.value
  })

  const isSessionTokenExpired = computed(() => {
    if (typeof sessionTokenExpiresAt.value === 'undefined') {
      return false
    }

    const expiresAt = dayjs(sessionTokenExpiresAt.value)

    if (!expiresAt.isValid()) {
      return true
    }

    return expiresAt.isBefore(dayjs(), 'milliseconds')
  })

  const user = ref<User>()

  const sessionTokenExpiresAt = useLocalStorage<string | undefined>(
    'session_token_expires_at',
    undefined
  )
  const sessionToken = useLocalStorage<string>('session_token', null, {
    flush: 'sync'
  })

  /**
   * This action is used to manually set the session token.
   * When the app is rendered through POS, we use the Pos API to obtain the auth
   * token from it so that users don't have to log in again with their email / password.
   * The expiry is set to undefined as we don't want this token to expire.
   */
  async function setSessionToken(session: string) {
    sessionToken.value = session
    sessionTokenExpiresAt.value = undefined
  }

  async function fetchUserData() {
    if (!isAuthenticated.value) {
      return new UnauthorizedError()
    }

    const { data, error, statusCode } = await useThFetch('/v0/me').json()

    if (data.value) {
      const userData: User = data.value.results[0].user

      user.value = userData

      return
    }

    return statusCode.value === 401 ? new UnauthorizedError() : (error.value as Error)
  }

  async function login(loginData: LoginData) {
    const { data, error } = await useThFetch('/v0/users/login', {
      onFetchError(ctx) {
        if (!ctx.response) {
          return ctx
        }

        const { response } = ctx
        const data: { msg: string; valid_password?: boolean } = ctx.data

        let error: Error = ctx.error

        if (response.status === 400) {
          error = new EmailNotFoundError()
        }

        if (response.status === 401) {
          if (data.valid_password === false) {
            error = new InvalidPasswordError()
          }

          if (data.msg?.includes('recaptcha')) {
            error = new InvalidRecaptchaError()
          }
        }

        return {
          ...ctx,
          error: error
        }
      }
    })
      .json<{ token: string }>()
      .post({
        email: loginData.email,
        password: loginData.password,
        recaptcha_token: loginData.recaptcha
      })

    if (data.value) {
      // When authenticating with email and password, the session is only valid for a day
      sessionToken.value = data.value.token
      sessionTokenExpiresAt.value = dayjs().add(1, 'day').toISOString()

      return
    }

    return error.value
  }

  function logout() {
    sessionToken.value = undefined
    sessionTokenExpiresAt.value = undefined
    user.value = undefined
  }

  return {
    setSessionToken,
    sessionToken: computed(() => sessionToken.value),
    user: computed(() => user.value),
    isAuthenticated,
    login,
    logout,
    fetchUserData
  }
})

export interface LoginData {
  email: string
  password: string
  recaptcha?: string
}

export class UnauthorizedError implements Error {
  public message = 'Unauthorized'
  public name = 'UnauthorizedError'
}

export class EmailNotFoundError implements Error {
  public name = 'EmailNotFoundError'
  public message = 'No user found with given email'
}

export class InvalidPasswordError implements Error {
  public name = 'InvalidPasswordError'
  public message = 'Invalid password'
}

export class InvalidRecaptchaError implements Error {
  public name = 'InvalidRecaptchaError'
  public message = 'Invalid recaptcha'
}
