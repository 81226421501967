import { useClientAccountId } from '@/composables/useClientAccountId'
import { useThFetch } from '@/composables/useThFetch'
import type { Staff } from '@/types'
import type { UseFetchOptions } from '@vueuse/core'

export function useStaffQuery(options?: UseFetchOptions) {
  const accountId = useClientAccountId()
  return useThFetch(() => `/v0/staff/${accountId.value}?limit=500&deleted=false`, {
    afterFetch(ctx) {
      return {
        ...ctx,
        data: ctx.data.results.map((staff: Staff) => ({
          ...staff,
          name:
            staff.firstname && staff.lastname
              ? `${staff.firstname} ${staff.lastname}`
              : staff.firstname || staff.lastname || ''
        }))
      }
    },
    ...options
  }).json<Staff[]>()
}
