import Notifications from '@kyvg/vue3-notification'
import type { Plugin } from 'vue'

export function createNotifications(): Plugin {
  return {
    install(app) {
      app.use(Notifications, { componentName: 'VueNotifications' })
    }
  }
}
