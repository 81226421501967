import { isNativeApp } from '@/constants/isNativeApp'
import { usePosApi } from '@/plugins/posApi/usePosApi'
import { useAuthStore } from '@/stores/useAuthStore'
import type { NavigationGuard } from 'vue-router'

export const authNavigationGuard: NavigationGuard = async (to, from) => {
  const authStore = useAuthStore()
  const posAPI = usePosApi()

  // We only want to do this once, when the app initializes, otherwise the auth guard will
  // catch the user after logging out and log him back him
  const shouldAttemptPOSAuthentication = !from.name && isNativeApp.value && !authStore.user

  if (shouldAttemptPOSAuthentication) {
    // If the app is rendered through POS and the user is not yet loaded,
    // try to get the session token from the POS.
    // If we can, we will preload it in the auth store and try to fetch the user data.
    // If that fails, they will be redirected to the login page.
    const response = await posAPI.getSession()
    const sessionToken = response?.message_payload?.session?.token

    if (sessionToken) {
      authStore.setSessionToken(sessionToken)
    }
  }

  // If we don't have user data, try to fetch it
  if (!authStore.user && authStore.isAuthenticated) {
    await authStore.fetchUserData()
  }

  // If we still don't have it, only the login route can be visited
  if (!authStore.user && to.name !== 'login') {
    return { name: 'login' }
  }

  // If navigating to the login page and the user data is loaded,
  // redirect to the calendar route
  if (authStore.user && to.name === 'login') {
    return { name: 'calendar' }
  }
}
