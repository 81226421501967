import type { PosEventMap } from './types/PosEventMap'

export function getSaleCompletedResponse(event: PosEventMap['sale_completed']) {
  const sale = event.message_payload.sale

  // Response comforms to this payload
  // https://github.com/tillhub/TillhubWebViewAPI-iOS?tab=readme-ov-file#f-04-sale-completed-pos--web
  return {
    message_header: {
      ...event.message_header,
      message_type: 'response'
    },
    message_payload: {
      sale: {
        id: sale.id,
        external_reference_custom_id: sale.external_reference_custom_id,
        total: sale.payments[0].amount,
        actions: {}
      },
      status: 'success'
    }
  }
}

export function getUpdateCartResponse(event: PosEventMap['update_cart']) {
  // Response comforms to this payload
  // https://github.com/tillhub/TillhubWebViewAPI-iOS?tab=readme-ov-file#f-03-update-cart-pos--web
  return {
    message_header: {
      ...event.message_header,
      message_type: 'response'
    },
    message_payload: {
      cart: event.message_payload.cart,
      status: 'success'
    }
  }
}
