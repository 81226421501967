import type { Configuration } from '@/types/Configuration'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'

export type ConfigurationStoreState = ReturnType<typeof useConfigurationStore>['$state']

export const useConfigurationStore = defineStore('configuration', () => {
  const configuration = ref() as Ref<Configuration>

  function set(value: Configuration) {
    configuration.value = value
  }

  return {
    configuration,
    set
  }
})
