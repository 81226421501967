import { Env } from '@/env'
import { useAuthStore } from '@/stores/useAuthStore'
import * as Sentry from '@sentry/vue'
import type { App } from 'vue'

/**
 * These errors tend to occur when a new release is deployed and the client has cached files locally
 * which have references to files that no longer exist.
 * Since these errors do not require fixing, we do not care to report them.
 */
const ignoreErrorPatterns = [
  'Failed to fetch dynamically imported module',
  'Unable to preload CSS for',
  'Importing a module script failed.'
]

function isErrorIgnored(error: unknown) {
  if (error instanceof Error) {
    return ignoreErrorPatterns.some((pattern) =>
      error.message?.toLowerCase().includes(pattern.toLowerCase())
    )
  }

  return false
}

export function useSentry() {
  return Sentry
}

export function createSentry() {
  return {
    install(app: App) {
      const router = app.config.globalProperties.$router

      Sentry.init({
        app,
        enabled: Env.DEV === false,
        dsn: Env.VITE_SENTRY_DSN,
        environment: Env.MODE,
        beforeSend(event, hint) {
          if (isErrorIgnored(hint.originalException)) {
            return null
          }

          /**
           * Add the organization name to each reported event so that we can
           * have some context regarding reported issues.
           */
          const { user } = useAuthStore(app.config.globalProperties.$pinia)

          event.extra = {
            organization: user?.display_name,
            ...event.extra
          }

          return event
        },
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router)
          })
        ]
      })
    }
  }
}
