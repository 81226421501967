<script setup lang="ts">
import type { NotificationItem } from '@kyvg/vue3-notification'
import ThIcon, { type ThIconName } from '@/components/ui/ThIcon.vue'

const iconsByType: Record<string, ThIconName> = {
  success: 'Check',
  error: 'Xmark'
}
</script>

<template>
  <VueNotifications position="top center" animation-type="css" animation-name="slide">
    <template #body="{ item }: { item: NotificationItem }">
      <div class="flex items-center p-5 bg-white" :class="item.type">
        <div
          class="w-8 h-8 rounded-full flex items-center justify-center mr-2.5 flex-shrink-0"
          :class="{
            'bg-green': item.type === 'success',
            'bg-red-300': item.type === 'error'
          }"
        >
          <ThIcon
            v-if="iconsByType[item.type!]"
            :is="iconsByType[item.type!]"
            class="w-4 text-white"
          />
        </div>
        <p>
          {{ item.title }}
        </p>
      </div>
    </template>
  </VueNotifications>
</template>

<style>
.vue-notification-group {
  text-align: center;
}

.vue-notification-wrapper {
  box-shadow: 0px 1px 16px 0px rgba(24, 39, 75, 0.12);
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  width: auto !important;
  display: inline-flex !important;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-100%) !important;
}
</style>
