import { type Plugin, watchEffect } from 'vue'
import { isNativeApp } from '@/constants/isNativeApp'
import { ampli, type Environment } from '../ampli'
import { useAuthStore } from '@/stores/useAuthStore'
import type { User } from '@/types/User'
import { Env } from '@/env'
import { storeToRefs } from 'pinia'

type Ampli = typeof ampli
type NonEventMethods = 'load' | 'track' | 'flush' | 'identify' | 'client'

type AmpliMethodNames = {
  [K in keyof Ampli]: Ampli[K] extends (...args: any[]) => any ? K : never
}[keyof Ampli]

type AmpliEvents = Exclude<AmpliMethodNames, NonEventMethods>

interface BaseEventProperties {
  platform_type: 'web' | 'ios'
  organization: string
}

type ExcludeBaseEventProps<T> = T extends BaseEventProperties
  ? Omit<T, keyof BaseEventProperties>
  : T

/**
 * Returns a type safe `track` proxy for usage in application code
 */
export function useAmpli() {
  return {
    track<T extends AmpliEvents, U extends Parameters<Ampli[T]>>(
      event: T,
      ...args: [ExcludeBaseEventProps<U[0]>?, U[1]?]
    ) {
      const { user } = storeToRefs(useAuthStore())
      const baseProperties: BaseEventProperties = {
        platform_type: isNativeApp.value ? 'ios' : 'web',
        organization: user.value?.display_name!
      }

      const eventProperties = {
        ...baseProperties,
        ...(args[0] || {})
      }

      const eventOptions = args[1]

      if (!isEnabled) {
        // If the plugin is not enabled, log it for debugging
        return console.info(`[Ampli Event: ${event}]`, {
          properties: eventProperties,
          options: eventOptions
        })
      }

      return ampli[event].apply(ampli, [eventProperties, eventOptions] as any) // eslint-disable-line
    }
  }
}

let isEnabled = false

function userAgreedToTracking() {
  if (!window.CookieScript?.instance) {
    return false
  }

  const state = window.CookieScript.instance.currentState()

  return state.categories?.includes('targeting')
}

async function loadAmpliIfAllowed(user: User) {
  if (!userAgreedToTracking()) {
    return
  }

  isEnabled = true

  if (!ampli.isLoaded) {
    let environment: Environment = 'default'

    if (Env.PROD) {
      environment = window.location.origin.includes('staging-appointment-calendar.tillhub.com')
        ? 'staging'
        : 'production'
    }

    await ampli.load({
      environment,
      client: {
        configuration: {
          defaultTracking: true,
          trackingOptions: {
            // Copied from dashboard, its important
            ipAddress: false
          }
        }
      }
    })
  }

  console.info('[ampli]: Plugin loaded!')

  ampli.client.setUserId(user.id)
}

/**
 * Creates the amplitude plugin. We listen for the Cookie Policy events
 * and if the user has agreed to be tracked, we enable the plugin.
 */
export function createAmpli(): Plugin {
  return {
    install() {
      const authStore = useAuthStore()

      watchEffect((onCleanup) => {
        const { user } = authStore

        if (!user && ampli.isLoaded) {
          // If the user logged out, reset the state
          ampli.client.reset()
        }

        if (!user) {
          return
        }

        /**
         * If the CookieScript has executed, try to load ampli immediately.
         * We do this, because if the user data loaded after the CookieScript, the Loaded event
         * will have fired off already and if the user accepted cookies in a previous session, we will
         * not get any of the other two events.
         */
        if (window.CookieScript?.instance) {
          loadAmpliIfAllowed(user)
        }

        const eventHandler = () => loadAmpliIfAllowed(user)

        const events = ['CookieScriptLoaded', 'CookieScriptAccept', 'CookieScriptAcceptAll']

        events.forEach((event) => {
          window.addEventListener(event, eventHandler)
        })

        onCleanup(() => {
          // Clean up events, handles user logging in / out with different accounts
          events.forEach((event) => {
            window.removeEventListener(event, eventHandler)
          })
        })
      })
    }
  }
}
