import dayjs from 'dayjs'
import type { App } from 'vue'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isoWeek from 'dayjs/plugin/isoWeek'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import de from 'dayjs/locale/de'
import en from 'dayjs/locale/en'
import { locale } from '@/plugins/i18n'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(duration)
dayjs.extend(isSameOrBefore)
dayjs.extend(isoWeek)
dayjs.extend(customParseFormat)

const locales = { de, en } as const

export { dayjs }

export function useDayjs() {
  return dayjs
}

dayjs.locale(locales[locale])

export async function createDayjs() {
  // const [locale] = window.navigator.language.split('-')

  // try {
  //   // Import the supported locales. The default one is English
  //   // If the import fails, we can just ignore it.
  //   if (locale.startsWith('de')) {
  //     await import('dayjs/locale/de')
  //     dayjs.locale('de')
  //   }
  // } catch (error) {
  //   console.error(error)
  // }

  return {
    install(app: App) {
      app.config.globalProperties.$dayjs = dayjs
    }
  }
}
