import { createAppRouter } from '@/router'
import { authNavigationGuard } from '@/router/guards/authNavigationGuard'
import type { Plugin } from 'vue'

export function createRouter(): Plugin {
  return {
    install(app) {
      const router = createAppRouter()

      router.beforeEach(authNavigationGuard)

      app.use(router)
    }
  }
}
