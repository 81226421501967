import type { Branch } from '@/types'
import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'

export type BranchesStoreState = ReturnType<typeof useBranchesStore>['$state']

export const useBranchesStore = defineStore('branches', () => {
  const branches = ref<Branch[]>([])
  const selectedBranch = ref() as Ref<Branch>

  function set(value: Branch[]) {
    // Sorts by date ascending
    branches.value = value.slice().sort((a, b) => {
      return a.created_at.unix - b.created_at.unix
    })
  }

  function setSelectedBranch(branch: Branch) {
    selectedBranch.value = branch
  }

  function clearSelectedBranch() {
    // This can be treated as non-nullable across the application, with the exception of
    // ChooseBranchView. Its protected by router guards
    // @ts-expect-error
    selectedBranch.value = undefined
  }

  return {
    selectedBranch,
    branches,
    set,
    setSelectedBranch,
    clearSelectedBranch
  }
})
