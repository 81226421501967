import mitt, { type Emitter } from 'mitt'
import { inject, type App, type InjectionKey, type Plugin } from 'vue'
import type { PosEventMap } from './types/PosEventMap'
import type { PosEvent } from './types/PosEvent'
import { useSentry } from '../sentry'
import { getSaleCompletedResponse, getUpdateCartResponse } from './utils'

type PosEmitter = Emitter<PosEventMap>

const INJECTION_KEY: InjectionKey<PosEmitter> = Symbol('POS_EMITTER')

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $posEmitter: PosEmitter
  }
}

export function usePosEmitter() {
  return inject(INJECTION_KEY)!
}

export function createPosEmitter(): Plugin {
  return {
    install(app: App) {
      const sentry = useSentry()
      const emitter = mitt<PosEventMap>()

      app.provide(INJECTION_KEY, emitter)
      app.config.globalProperties.$posEmitter = emitter

      /**
       * The POS will call this global function and pass the event payload serialized
       * as the first argument.
       * @docs https://github.com/tillhub/TillhubWebViewAPI-iOS?tab=readme-ov-file#f-04-sale-completed-pos--web
       */
      window.handleRequestFromPos = async (payload: PosEvent) => {
        try {
          const eventName = payload.message_header.message_name

          emitter.emit(eventName, payload as PosEventMap[typeof eventName])

          let returnValue

          if (eventName === 'sale_completed') {
            returnValue = getSaleCompletedResponse(payload as PosEventMap['sale_completed'])
          } else if (eventName === 'update_cart') {
            // We don't keep a cart object on our end, so we just acknowledge that we received the event
            returnValue = getUpdateCartResponse(payload as PosEventMap['update_cart'])
          }

          return JSON.stringify(returnValue, null, 4)
        } catch (error) {
          sentry.captureException(error)
          throw error
        }
      }
    }
  }
}
