<script lang="ts">
import Calendar from '@/assets/icons/Calendar.svg'
import Check from '@/assets/icons/Check.svg'
import ChevronLeft from '@/assets/icons/ChevronLeft.svg'
import ChevronRight from '@/assets/icons/ChevronRight.svg'
import ChevronDown from '@/assets/icons/ChevronDown.svg'
import CircleExclamation from '@/assets/icons/CircleExclamation.svg'
import Clock from '@/assets/icons/Clock.svg'
import PenToSquare from '@/assets/icons/PenToSquare.svg'
import Plus from '@/assets/icons/Plus.svg'
import Search from '@/assets/icons/Search.svg'
import Settings from '@/assets/icons/Settings.svg'
import ShoppingCart from '@/assets/icons/ShoppingCart.svg'
import Trash from '@/assets/icons/Trash.svg'
import UserPlus from '@/assets/icons/UserPlus.svg'
import Xmark from '@/assets/icons/Xmark.svg'
import Spinner from '@/assets/icons/Spinner.svg'
import CircleXmark from '@/assets/icons/CircleXmark.svg'
import ChevronUpDown from '@/assets/icons/ChevronUpDown.svg'
import SignOut from '@/assets/icons/SignOut.svg'
import ArrowRight from '@/assets/icons/ArrowRight.svg'
import Computer from '@/assets/icons/Computer.svg'
import Refresh from '@/assets/icons/Refresh.svg'
import Note from '@/assets/icons/Note.svg'
import InfoCircle from '@/assets/icons/InfoCircle.svg'

export type ThIconName = keyof typeof IconMap

const IconMap = {
  Calendar,
  Check,
  ChevronLeft,
  ChevronRight,
  CircleExclamation,
  Clock,
  PenToSquare,
  Plus,
  Search,
  Settings,
  ShoppingCart,
  Trash,
  UserPlus,
  Xmark,
  Spinner,
  CircleXmark,
  ChevronUpDown,
  SignOut,
  ArrowRight,
  ChevronDown,
  Computer,
  Refresh,
  Note,
  InfoCircle
}
</script>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{ is: ThIconName }>()

const component = computed(() => IconMap[props.is])
</script>

<template>
  <component class="th-icon" v-if="component" :is="component" />
</template>
