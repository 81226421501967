import { computed, onUnmounted, ref, type MaybeRefOrGetter, watch, toValue } from 'vue'

// Since multiple components can call `show/hide` simultaneously we keep track of the count
// so that we dont hide the spinner when something else is still loading
const counter = ref(0)
export const isShown = computed(() => counter.value > 0)

/**
 * Displays a loading overlay on top of the application.
 * You can use either show/hide methods to manually display it or pass in a
 * `loading` ref and the overlay will be shown when it evaluates to true
 */
export function useLoadingOverlay(isLoadingRef?: MaybeRefOrGetter<boolean>) {
  let isShown = false

  if (typeof isLoadingRef !== 'undefined') {
    watch(
      () => toValue(isLoadingRef),
      (isLoading) => {
        isLoading ? show() : hide()
      },
      {
        immediate: true
      }
    )
  }

  function show() {
    if (isShown) {
      return
    }

    isShown = true
    counter.value++
  }

  function hide() {
    if (!isShown) {
      return
    }

    isShown = false
    counter.value--
  }

  onUnmounted(hide)

  return { show, hide }
}
