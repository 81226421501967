import type { App } from 'vue'
import { createI18n } from './i18n'
import { createPinia } from './pinia'
import { createDayjs } from './dayjs'
import { createAmpli } from './ampli'
import { createSentry } from './sentry'
import { createNotifications } from './notifications'
import { createCookiePolicy } from './cookiePolicy'
import { createPosEmitter } from './posEmitter'
import { createRouter } from './router'

export async function createPlugins(app: App) {
  // Create the router first, in case some of them, in case
  // some of the other plugins needs to reference it ( like Sentry )
  app.use(createRouter())

  app.use(createSentry())
  app.use(createI18n())
  app.use(createPinia())
  app.use(createPosEmitter())
  app.use(await createDayjs())
  app.use(createNotifications())
  app.use(createAmpli())
  app.use(createCookiePolicy())
}
