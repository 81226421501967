import { useClientAccountId } from '@/composables/useClientAccountId'
import { useThFetch } from '@/composables/useThFetch'
import type { Branch } from '@/types'
import type { UseFetchOptions } from '@vueuse/core'

export function useBranchesQuery(options?: UseFetchOptions) {
  const accountId = useClientAccountId()

  return useThFetch(() => `/v0/branches/${accountId.value}?owner=self&deleted=false`, {
    afterFetch(ctx) {
      return {
        ...ctx,
        data: ctx.data.results
      }
    },
    ...options
  }).json<Branch[]>()
}
