import { useAuthStore } from '@/stores/useAuthStore'
import { computed } from 'vue'

export function useClientAccountId() {
  const authStore = useAuthStore()

  return computed(() => {
    const { user } = authStore

    return user?.legacy_id ?? user?.id
  })
}
