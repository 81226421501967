import { Env } from '@/env'
import { useAuthStore } from '@/stores/useAuthStore'
import { createFetch } from '@vueuse/core'
import fetchRetry from 'fetch-retry'

const retryableFetch = fetchRetry(window.fetch, {
  retryOn(retries, error, response) {
    // 0 based
    if (retries > 2) {
      return false
    }

    // Network errors, the request was not sent
    if (!response || error) {
      return true
    }

    // timeout, too many requests
    if ([408, 429].includes(response.status)) {
      return true
    }

    if (response.status >= 500) {
      return true
    }

    return false
  },
  retryDelay: (attempt) => (attempt + 1) * 1000
})

/**
 * Creates an instance of useFetch with the backend url baked in
 */
export const useThFetch = createFetch({
  baseUrl: Env.VITE_VUE_APP_API_BASE,
  combination: 'chain',
  options: {
    // In test environments, we do not need retries
    fetch: Env.MODE === 'test' ? undefined : retryableFetch,
    onFetchError(ctx) {
      /**
       * Logs out the user if we received an Unauthorized error
       */
      if (ctx.response?.status === 401) {
        useAuthStore().logout()
      }

      return ctx
    },
    beforeFetch(ctx) {
      const { sessionToken } = useAuthStore()

      ctx.options.headers = {
        ...ctx.options.headers,
        Authorization: `Bearer ${sessionToken}`
      }

      return ctx
    }
  }
})
