import type { UseFetchReturn } from '@vueuse/core'
import { computed } from 'vue'

/**
 * Accepts an array of queries and allows you to monitor them in bulk
 * isError - at least one of the queries has failed
 * isFetching - at least one of the queries is fetching
 */
export function useQueryBatch(queries: UseFetchReturn<any>[]) {
  const isFetching = computed(() => queries.some((query) => query.isFetching.value))
  const isError = computed(() => queries.some((query) => !!query.error.value))

  async function execute(throwOnFailed?: boolean) {
    await Promise.all(queries.map((query) => query.execute(throwOnFailed)))
  }

  return { isFetching, isError, execute }
}
