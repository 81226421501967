<script setup lang="ts">
import { RouterView, useRouter } from 'vue-router'
import { useConfigurationQuery } from './api/useConfigurationQuery'
import { useStaffQuery } from './api/useStaffQuery'
import { computed, onErrorCaptured, ref, watch } from 'vue'
import { useConfigurationStore } from './stores/useConfigurationStore'
import { useStaffStore } from './stores/useStaffStore'
import { useAuthStore } from './stores/useAuthStore'
import { useQueryBatch } from '@/composables/useQueryBatch'
import NotificationsContainer from './plugins/notifications/NotificationsContainer.vue'
import WebAppHeader from './components/WebAppHeader.vue'
import { isNativeApp } from '@/constants/isNativeApp'
import LoadingOverlay from '@/plugins/loadingOverlay/LoadingOverlay.vue'
import { useLoadingOverlay } from '@/plugins/loadingOverlay/loadingOverlay'
import { useBranchesQuery } from '@/api/useBranchesQuery'
import { useBranchesStore } from '@/stores/useBranchesStore'
import ErrorView from './views/ErrorView.vue'
import { Env } from '@/env'
import { usePosApi } from '@/plugins/posApi/usePosApi'
import { useLocalStorage } from '@vueuse/core'
import type { Branch } from '@/types'
import { storeToRefs } from 'pinia'

const authStore = useAuthStore()
const configurationStore = useConfigurationStore()
const staffStore = useStaffStore()
const branchesStore = useBranchesStore()
const router = useRouter()
const posAPI = usePosApi()

const { selectedBranch } = storeToRefs(branchesStore)

const layout = computed(() => router.currentRoute.value.meta.layout || 'default')

const configurationQuery = useConfigurationQuery({ immediate: false })
const staffQuery = useStaffQuery({ immediate: false })
const branchesQuery = useBranchesQuery({ immediate: false })

// Saves the id of the last selected branch in local storage.
// When the user re-enters the page, we will try to obtain the previous branch id from POS, then local storage
// If neither works, he is redirected to the Choose Branch view.
const persistedBranchId = useLocalStorage<string | undefined>('branch_id', undefined)

watch(
  () => branchesStore.selectedBranch,
  (branch) => {
    // When logging out, this will become null. We dont want to clear it
    // so that it is remembered if the user logs in with the same account
    if (branch) {
      persistedBranchId.value = branch.id
    }
  }
)

async function guessSelectedBranch(branches: Branch[]) {
  // If the saloon only has one branch, return it
  if (branches.length === 1) {
    return branches[0]
  }

  const response = await posAPI.getActiveBranch()

  // If the app is rendered from POS, get the selected branch from it
  // Otherwise, get the latest selected branch id from local storage
  const branchId = response?.message_payload?.branch?.id || persistedBranchId.value

  if (!branchId) {
    return null
  }

  return branches.find((branch) => branch.id === branchId) || null
}

const showErrorView = ref(false)

if (Env.PROD) {
  // In production environments, if an uncaught error reaches the root component
  // we show the global error page. We dont want to do this locally because it would cause
  // the app to lose context which would make debugging harder.
  onErrorCaptured(() => {
    showErrorView.value = true
  })
}

const resourceQueries = useQueryBatch([configurationQuery, staffQuery, branchesQuery])

const isLoading = ref(false)
const isInitialized = ref(false)

useLoadingOverlay(isLoading)

/**
 * When the user changes, reset the initialized state so that we fetch fresh data
 * in case they changed their account
 */
watch(
  () => authStore.user,
  () => {
    isInitialized.value = false
  }
)

/**
 * We use a hook here, because we want to block the navigation until the global state has been
 * fetched. This is done because if the user has not selected a valid branch, he must be redirected
 * to the ChooseBranch step, but we can only know if heh as selected a valid one after we fetch
 * them from the database.
 */
router.beforeEach(async (to) => {
  // This navigation guard deals with global state fetching after the user data is loaded
  // successfully. If it hasnt, we dont want to do anything as that is handled by authNavigationGuard
  if (!authStore.user) {
    return
  }

  if (!isInitialized.value) {
    await fetchGlobalState()
  }

  // If there's no selected branch after global state is fetched, only the ChooseBranch view is allowed.
  if (!selectedBranch.value && to.name !== 'choose_branch') {
    return { name: 'choose_branch' }
  }
})

/**
 * Fetches the global state for the application. This state is required for all routes
 * besides LoginView
 */
async function fetchGlobalState() {
  try {
    isLoading.value = true
    isInitialized.value = false

    await resourceQueries.execute()

    if (resourceQueries.isError.value) {
      showErrorView.value = true

      return
    }

    configurationStore.set(configurationQuery.data.value!)
    staffStore.set(staffQuery.data.value!)
    branchesStore.set(branchesQuery.data.value!)

    const { branches } = branchesStore

    if (branches) {
      const selectedBranch = await guessSelectedBranch(branches)

      if (selectedBranch) {
        branchesStore.setSelectedBranch(selectedBranch)
      }
    }

    isInitialized.value = true
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="relative h-full">
    <ErrorView v-if="showErrorView" />

    <RouterView v-else-if="layout === 'auth'" />

    <div v-else-if="selectedBranch" class="h-full flex flex-col overflow-hidden">
      <WebAppHeader v-if="!isNativeApp.value" />

      <RouterView />
    </div>

    <LoadingOverlay />

    <NotificationsContainer />
  </div>
</template>
